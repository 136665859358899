// font family
$font_family: Helvetica, Arial, sans-serif !important;

// colors
$tasso-bright-blue: #5067eb;
$tasso-blue: #091F5B;
$tasso-dark-blue: #031137;
$tasso-red: #EA5153;
$tasso-violet: #95A4FF;
$white: #fff;

$grey1: #fbfbfb;
$grey2: #f8f8f8;
$grey3: #4f4f4f;
$grey4: #333333;
$grey5: #828282;
$grey6: #c4c4c4;

$semi-grey: #f4f4f4;
$light-grey: #fbfbfb;
$icon-grey: #c4c4c4;

$semi-dark: #333333;
$dark-text: #1a1a1a;
$border-grey: #828282;
