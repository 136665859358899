@import 'src/sass/abstracts/mixins';
@import 'src/sass/abstracts/variables';

body {
  margin: 0;
  font-family: helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* hide input icons in IE 11 */
input[type='text']::-ms-clear,
input[type='email']::-ms-clear,
input[type='password']::-ms-clear,
input[type='password']::-ms-reveal {
  display: none;
}
