@import 'src/sass/abstracts/mixins';
@import 'src/sass/abstracts/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding-left: 50px;
  padding-right: 0px;

  .logo {
    height: 32px;
    width: 130px;
    cursor: pointer;
  }

  .menu {
    &_title {
      width: 300px;
      margin-right: 10px;
      padding: 0 5px;
      height: 36px;
      justify-content: space-between;

      .menu_username {
        // display: inline;
        white-space: nowrap;
        color: #000000;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }

      -webkit-border-bottom-right-radius: 0 !important;
      -webkit-border-bottom-left-radius: 0 !important;

      -moz-border-radius-bottomright: 0 !important;
      -moz-border-radius-bottomleft: 0 !important;

      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;

      border-bottom: 1px solid $border-grey;

      svg {
        margin-right: 10px;
        color: $icon-grey;
      }
    }

    &_paper {
      width: 300px;
      z-index: 9999;
      -webkit-border-top-left-radius: 0 !important;
      -webkit-border-top-right-radius: 0 !important;

      -moz-border-radius-topleft: 0 !important;
      -moz-border-radius-topright: 0 !important;

      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }

  .header_account_icon {
    width: 27px;
    height: 27px;
  }
}
